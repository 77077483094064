<template>
  <div>
    <section class="associate">
      <div class="associate-content">
        <div>
          <div class="associate-block"></div>
          <div class="associate-title">Invista em <span>quem te defende</span></div>
          <a href="/seja-socio">
            <button class="associate-button">SEJA SÓCIO</button>
          </a>
        </div>
        <img src="../../../../assets/group-of-people.jpg" alt="group of people" class="associate-image" />
      </div>
    </section>

    <section class="destaques">
      <div class="container">
        <Title  :title="'Destaques'"/>
      </div>
       <destaques-home/>
    </section>

    <section class="noticias">
      <div class="container">
        <div class="news_header_container">
          <Title :title="'Últimas Notícias'"/>
          <router-link to="/news" class="mais_noticias">
            mais notícias
            <img src="../../../../assets/setas.svg" alt="">
          </router-link>
        </div>
        <news-home/>
      </div>
    </section>

      <section class="gallery d-flex flex-column">
        <div class="container">
          <Title title="Galeria" />
          <div class="gallery-text-container">
            <p class="text-gallery text-center">
              Nossos eventos, confraternizações e os melhores momentos estão registrados aqui.
            </p>
            <router-link to="/gallery" class="gallery-link">Ver galeria completa</router-link>
          </div>
        </div>
      </section>

      <section class="parceiros">
        <div class="container">
          <Title title="Parceiros"/>
        </div>
        <affiliates-home />
      </section>
  </div>
</template>
<script>
export default {
  name: "Home",
  components: {
    // Publication: () => import("../../../../components/commons/Publication"),
    Title: () => import("../../../../components/web/Title"),
    DestaquesHome: () => import("@/layouts/web/home/DestaquesHome.vue"),
    GaleryHome: () => import("@/layouts/web/home/GaleryHome.vue"),
    // ClubHome: () => import("@/layouts/web/home/ClubHome.vue"),
    AffiliatesHome: () => import("@/layouts/web/home/AffiliatesHome.vue"),
    NewsHome: () => import("@/layouts/web/home/NewsHome.vue"),

  },
 metaInfo: {
    title: "SEEB | Home",
     meta: [
      { name: 'description', content: 'Todas as informaçẽos do sindicato dos bancarios de Uberlândia.' }
    ]

  },  props: {
    id: {
      type: String,
      default: "home"
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1230px;
}

.gallery {
  padding-top: 30px;
  height: 337px;
  background-image: url('../../../../assets/divider.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .gallery-text-container {
    text-align: center;
    margin-bottom: 40px;
  }
  .text-gallery {
    color: #334666;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
  }
  .gallery-link {
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Inter";
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
    background-color:#1C8BE3;
    padding: 10px 14px;
    border-radius: 6px;
  }

  @media screen and (max-width: 987px) {
    background: #F7F7F7;
  }
}

.associate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.associate-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0px 100px;
  background: #334666;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)
}

.associate-block {
  width: 30px;
  height: 2px;
  background: #ffffff;
}

.associate-button {
  color: #334666;
  padding: 5px 15px;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 5px;
}

.associate-button:hover {
  color: #ffffff;
  background-color: #1E88E5;
}

.associate-title {
  color: #ffffff;
  font-size: 40px;
}

.associate-title span {
  padding: 5px;
  color: #334666;
  background-color: #ffffff;
}

.associate-image {
  height: 250px;
}

.destaques {
  margin: 30px 0px;
}
.news_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mais_noticias {
  display: flex;
  align-items: center;
  color: #334666;

  img {
    margin-left: 5px;
  }
}

</style>
